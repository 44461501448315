import { useEffect, useState } from "react";
import {
  buscarTipoOcorrencia,
  adicionarTipoOcorrencia,
  alterarTipoOcorrencia,
  buscarTiposOcorrencia,
} from "../../store/actions/index";
import { format } from "date-fns";
import Cabecalho from "../../componentes/cabecalho";
import Tabela from "../../componentes/tabela";
import { ajustarDataLocal } from "../../bibliotecas/funcoes";
import { IconButton, Tooltip, Alert, Stack, Typography, Box, Button, Grid, TextField, Select, MenuItem } from "@mui/material";
import Cached from "@mui/icons-material/Cached";
import AddIcon from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import AbcIcon from "@mui/icons-material/Abc";
import { useAtomValue } from "jotai";
import { empresaAtivaAtom } from "../../store";

const campos = [
  //nome
  { key: "nome", label: "Nome", width: "60%", align: "left" },
  //ativo
  { key: "situacao", label: "Situação", width: "10%", align: "center", fill: true },
  //dataCriacao
  { key: "criacao", label: "Criação", width: "10%", align: "center" },
  //alterar
  { key: "acoes", label: "Ações", width: "20%", align: "right" },
];

const situacoes = [
  { key: 1, nome: "Ativo", value: true },
  { key: 2, nome: "Inativo", value: false },
];

const TiposOcorrencia = (props) => {
  const [modoTela, setModoTela] = useState(props.modoTela);
  const [carregando, setCarregando] = useState(true);
  const [dadosAlerta, setDadosAlerta] = useState({
    exibir: false,
    mensagem: "",
  });
  const [atualizarLista, setAtualizarLista] = useState(false);
  const [dadosTabela, setDadosTabela] = useState([]);
  const [dadosFiltrados, setDadosFiltrados] = useState([]);
  const empresaAtiva = useAtomValue(empresaAtivaAtom);
  const [dadosItemAtivo, setDadosItemAtivo] = useState({
    id: null,
    nome: "",
    ativo: true,
    idEmpresa: empresaAtiva.id ?? null,
    dataCriacao: null,
  });
  const estadoInicialFiltro = {
    nome: "",
    situacao: [],
  };
  const [filtros, setFiltros] = useState(estadoInicialFiltro);

  useEffect(() => {
    (async () => {
      setCarregando(true);
      if (modoTela === "listar") {
        const res = await buscarTiposOcorrencia(
          JSON.stringify({
            situacoes: null,
            idEmpresa: empresaAtiva.id ?? null,
            listarEmpresasManuais: empresaAtiva.id ? false : true,
          })
        );
        if (res.tipo === "SUCESSO") {
          handleCarregarLista(res.dados);
          setDadosItemAtivo({
            id: null,
            nome: "",
            ativo: true,
            idEmpresa: empresaAtiva.id ?? null,
          });
          setCarregando(false);
        } else if (res.tipo === "ERRO") {
          handleExibirErro(true, res.mensagem);
        }
      } else {
        setCarregando(false);
      }
    })();
  }, [empresaAtiva.id, modoTela, atualizarLista]);

  const handleAlterarModoTela = (modoTela) => {
    handleExibirErro(false, "");
    setModoTela(modoTela);
  };

  const handleExibirErro = (exibir, mensagem) => {
    setCarregando(false);
    setDadosAlerta({ exibir: exibir, mensagem: mensagem });
  };

  const handleAdicionar = async () => {
    const res = await adicionarTipoOcorrencia({
      nome: dadosItemAtivo.nome,
      ativo: dadosItemAtivo.ativo,
      idEmpresa: dadosItemAtivo.idEmpresa,
    });
    if (res.tipo === "SUCESSO") {
      handleAlterarModoTela("listar");
    } else if (res.tipo === "ERRO") {
      handleExibirErro(true, res.mensagem);
    }
  };

  const handleAlterar = async () => {
    const res = await alterarTipoOcorrencia(dadosItemAtivo.id, {
      nome: dadosItemAtivo.nome,
      ativo: dadosItemAtivo.ativo,
      idEmpresa: dadosItemAtivo.idEmpresa,
    });
    if (res.tipo === "SUCESSO") {
      handleAlterarModoTela("listar");
    } else if (res.tipo === "ERRO") {
      handleExibirErro(true, res.mensagem);
    }
  };

  const handleAlterarSituacao = async (id, novaSituacao) => {
    setCarregando(true);
    let objetoFuncao = dadosTabela.filter((tipoOcorrencia) => tipoOcorrencia.id === id)[0];

    const res = await alterarTipoOcorrencia(id, {
      nome: objetoFuncao.nome,
      ativo: novaSituacao,
      idEmpresa: objetoFuncao.idEmpresa,
    });

    if (res.tipo === "SUCESSO") {
      let novosDadosTabela = [...dadosTabela];
      let iTabela = novosDadosTabela.findIndex((item) => item.id === id);
      if (iTabela !== -1) {
        novosDadosTabela[iTabela].ativo = novaSituacao;
        setDadosTabela(novosDadosTabela);
      }
      let novosDadosFiltrados = [...dadosFiltrados];
      let iFiltrados = novosDadosFiltrados.findIndex((item) => item.id === id);
      if (iFiltrados !== -1) {
        novosDadosFiltrados[iFiltrados].ativo = novaSituacao;
        setDadosFiltrados(novosDadosFiltrados);
      }
      handleAlterarModoTela("listar");
      setCarregando(false);
    }
  };

  const handleAtualizarLista = () => {
    setAtualizarLista(!atualizarLista);
  };

  const handleChangeFiltro = (event) => {
    const { id, name, value } = event.target;
    let filtrosLocal = { ...filtros };

    if (id === "nome") {
      filtrosLocal = { ...filtrosLocal, nome: value };
    } else if (name === "situacao") {
      filtrosLocal = { ...filtrosLocal, situacao: value };
    }
    setFiltros(filtrosLocal);
    handleFiltrarDados(dadosTabela, filtrosLocal);
  };

  const handleFiltrarDados = (dados, filtrosLocal) => {
    let itens = [...dados];
    if (filtrosLocal.nome !== "") {
      itens = (itens.filter((item) => item.nome.toUpperCase().includes(filtrosLocal.nome.toUpperCase())));
    }
    if (filtrosLocal.situacao.length > 0) {
      let filtroSituacoesCodigos = filtrosLocal.situacao.map((situacao) => situacao.value);
      filtroSituacoesCodigos = filtroSituacoesCodigos.length === 0 ? null : filtroSituacoesCodigos;
      if (filtroSituacoesCodigos) {
        filtroSituacoesCodigos = [...new Set(filtroSituacoesCodigos.map(x => x))];
        itens = itens.filter((item) => filtroSituacoesCodigos.includes(item.ativo) === true);
      }
    }
    setDadosFiltrados(itens);
  };

  const handleEditar = async (idTipoOcorrencia) => {
    const res = await buscarTipoOcorrencia(idTipoOcorrencia);
    if (res.tipo === "SUCESSO") {
      setDadosItemAtivo({
        id: res.dados.id,
        nome: res.dados.nome,
        ativo: res.dados.ativo,
        idEmpresa: res.dados.idEmpresa,
        dataCriacao: res.dados.dataCriacao,
      });
      handleAlterarModoTela("alterar");
    }
  };

  const handleRecuperarCorSituacao = (ativo) => {
    return ativo ? "success" : "secondary";
  };

  const handlePreencher = (e) => {
    handleExibirErro(false, "");
    const { id, value } = e.target;

    switch (id) {
      case "nome":
        setDadosItemAtivo({
          ...dadosItemAtivo,
          nome: value,
        });
        break;
      default:
    }
  };

  const renderNome = ({ nome }) => {
    return (
      <Typography component="div" variant="subtitle3" color="text.primary">
        {nome}
      </Typography>
    );
  };

  const renderSituacao = ({ ativo }) => {
    return (
      <Button variant="contained" sx={{ fontSize: 11 }} size="small" color={handleRecuperarCorSituacao(ativo)} disableElevation disableRipple disableFocusRipple disableTouchRipple>
        {ativo ? "Ativo" : "Inativo"}
      </Button>
    );
  };

  const renderCriacao = ({ dataCriacao }) => {
    let dataCriacaoAjustada = ajustarDataLocal(dataCriacao);
    let strDataCriacao = format(dataCriacaoAjustada, "dd-MM-yyyy");

    return (
      <Typography component="div" variant="subtitle3" color="text.secondary">
        {strDataCriacao}
      </Typography>
    );
  };

  const renderAcoes = ({ id, ativo }) => {
    let acaoAlterar = (
      <Tooltip title="alterar">
        <IconButton color="secondary" onClick={() =>
          modoTela === "adicionar"
            ? handleAdicionar()
            : handleEditar(id)
        }>
          <Edit />
        </IconButton>
      </Tooltip>
    );

    let acaoReativar = !ativo ? (
      <Tooltip title="reativar">
        <IconButton color="secondary" onClick={() => {
          handleAlterarSituacao(id, true);
        }}>
          <PlaylistAddIcon />
        </IconButton>
      </Tooltip>
    ) : null;

    let acaoDesativar = ativo ? (
      <Tooltip title="desativar">
        <IconButton color="secondary" onClick={() => {
          handleAlterarSituacao(id, false);
        }}>
          <PlaylistRemoveIcon />
        </IconButton>
      </Tooltip>
    ) : null;

    return (
      <>
        {acaoReativar}
        {acaoDesativar}
        {acaoAlterar}
      </>
    );
  };

  const handleCarregarLista = (dados) => {
    let itens = [];
    dados.map((item) => itens.push({
      id: item.id,
      nome: item.nome,
      ativo: item.ativo,
      dataCriacao: item.dataCriacao,
    }));
    setDadosTabela(itens);
    handleFiltrarDados(itens, filtros);
  };

  const renderItem = (item) => {
    return ({
      nome: renderNome({ nome: item.nome }),
      situacao: renderSituacao({ ativo: item.ativo }),
      criacao: renderCriacao({ dataCriacao: item.dataCriacao }),
      acoes: renderAcoes({ id: item.id, ativo: item.ativo }),
    });
  };

  let conteudo = null;

  if (!carregando) {
    let componenteAlerta = null;

    if (dadosAlerta.exibir) {
      componenteAlerta = (
        <Box sx={{ flexGrow: 1, padding: 1 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">{dadosAlerta.mensagem}</Alert>
          </Stack>
        </Box>
      );
    }

    if (modoTela === "listar") {
      let componenteBotoes = (
        <>
          <Tooltip title="adicionar novo tipo de contato">
            <IconButton color="secondary" onClick={() => handleAlterarModoTela("adicionar")} >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="atualizar lista">
            <IconButton color="secondary" onClick={handleAtualizarLista} >
              <Cached />
            </IconButton>
          </Tooltip>
        </>
      );

      conteudo = (
        <Tabela
          textoCabecalho={"Listar Tipos de Contato"}
          botoesCabecalho={componenteBotoes}
          itens={dadosFiltrados}
          campos={campos}
          alerta={dadosAlerta}
          renderItem={renderItem}
          //nome, situacao, criaao, acoes
          filtros={[
            <TextField
              id="nome"
              autoComplete="off"
              autoCorrect="off"
              fullWidth
              type="text"
              value={filtros.nome}
              onChange={handleChangeFiltro}
              InputProps={{
                style: { fontSize: 11 },
              }}
              size="small"
            />,
            <Select
              id="situacao"
              name="situacao"
              multiple
              fullWidth
              multiline={false}
              value={filtros.situacao}
              onChange={handleChangeFiltro}
              sx={{ fontSize: 11 }}
              size="small"
            >
              {situacoes.map((situacao) => (
                <MenuItem
                  key={situacao.value}
                  value={situacao}
                  sx={{ fontSize: 11 }}
                >
                  {situacao.nome}
                </MenuItem>
              ))}
            </Select>,
            <></>,
            <></>,
          ]}
        />
      );
    } else if (modoTela === "adicionar" || modoTela === "alterar") {
      conteudo = (
        <Box sx={{ display: "grid", gridTemplateRows: "auto 1fr auto auto" }}>
          <Box sx={{ flexGrow: 1, padding: 1, display: "flex", alignItems: "center" }}>
            <Cabecalho texto={modoTela === "adicionar"
              ? "Adicionar Tipo de Contato"
              : "Alterar Tipo de Contato"} />
          </Box>
          <Box sx={{ flexGrow: 1, padding: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ backgroundColor: "primary.main" }}>
                <Tooltip title="nome do tipo de contato">
                  <AbcIcon sx={{ color: "primary.contrastText", mx: 1, my: 0.5 }} />
                </Tooltip>
              </Box>
              <TextField
                id="nome"
                type="text"
                variant="standard"
                placeholder="nome"
                autoComplete="off"
                autoFocus
                fullWidth
                value={dadosItemAtivo.nome}
                onChange={handlePreencher}
                InputProps={{
                  style: { fontSize: "small", p: 0 },
                }}
                sx={{ pl: 1 }}
              />
            </Box>

          </Box>
          {componenteAlerta}
          <Box sx={{ display: "flex" }} p={1}>
            <Grid container sx={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}>
              <Button variant="contained" sx={{ fontSize: 11 }} size="small" color="primary"
                startIcon={<CheckIcon />}
                onClick={() => {
                  modoTela === "adicionar"
                    ? handleAdicionar()
                    : handleAlterar();
                }}
                disabled={
                  dadosItemAtivo.nome === ""
                }
              >
                Confirmar
              </Button>
              <Button variant="contained" sx={{ fontSize: 11, marginLeft: 1 }} size="small" color="primary"
                startIcon={<ClearIcon />}
                onClick={() => handleAlterarModoTela("listar")}
              >
                Cancelar
              </Button>
            </Grid>
          </Box>
        </Box >
      );
    }
  }

  return conteudo;
};

export default TiposOcorrencia;
