import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const handleUriS3 = async (key, path) => {
    try {
        const s3 = new S3Client({
            region: process.env.REACT_APP_BUCKET_ANEXOS_REGIAO,
            credentials: {
                accessKeyId: process.env.REACT_APP_BUCKET_ANEXOS_KEY,
                secretAccessKey: process.env.REACT_APP_BUCKET_ANEXOS_SECRET,
            },
        });

        const command = new GetObjectCommand({
            Bucket: process.env.REACT_APP_BUCKET_ANEXOS_NOME,
            Key: path + "/" + key,
            ACL: 'public-read',
        });

        let uri = await getSignedUrl(s3, command, { expiresIn: 3600 });

        if (uri) {
            return uri;
        } else {
            throw new Error("impossível resolver comando");
        }
    } catch (e) {
        throw new Error("Erro ao gerar URL S3: ", e?.message);
    }
};

const handleUriLocal = (key, path) => {
    try {
        let uri = null;
        if (process.env.NODE_ENV === "production") {
            uri =
                (process.env.REACT_APP_PROTOCOL === "HTTPS"
                    ? "https://"
                    : "http://" || "http://") +
                (process.env.REACT_APP_HOST_SERVER || "localhost") +
                `/public/${path}/${key}`.replace(/\\/g, String.fromCharCode(47));
        } else {
            uri =
                (process.env.REACT_APP_PROTOCOL === "HTTPS"
                    ? "https://"
                    : "http://" || "http://") +
                (process.env.REACT_APP_HOST_SERVER + ":" || "localhost:") +
                (process.env.REACT_APP_PORT_SERVER || "8080") +
                `/public/${path}/${key}`.replace(/\\/g, String.fromCharCode(47));
        }

        return uri;
    } catch (e) {
        throw new Error(e?.message || "Erro ao gerar URL local");
    }
};

const handleGerarUri = async (key, pathS3, pathLocal) => {
    if (process.env.REACT_APP_TIPO_STORAGE === "S3") {
        return await handleUriS3(key, pathS3);
    } else {
        return new Promise.resolve(handleUriLocal(key, pathLocal));
    }
};

export {
    handleGerarUri,
};