import http from "../http-common";
import httpForm from "../http-commonForm";
import authHeader from "../bibliotecas/authHeader";

class DemandasDataService {
  buscarTodas(filtros) {
    return http.get(`/demandas/${filtros}`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/demanda/${id}`, { headers: authHeader() });
  }

  adicionar(data) {
    return httpForm.post(`/demanda`, data, { headers: authHeader() });
  }

  alterar(id, data) {
    return http.put(`/demanda/${id}`, data, { headers: authHeader() });
  }

  adicionarConversa(data) {
    return httpForm.post(`/conversa`, data, { headers: authHeader() });
  }

  removerConversa(dados) {
    return http.delete(`/conversa/${dados}`, { headers: authHeader() });
  }

  remover(id) {
    return http.delete(`/demanda/${id}`, { headers: authHeader() });
  }
}

const demandasDataService = new DemandasDataService();

export default demandasDataService;

