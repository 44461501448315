import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function Cabecalho(props) {
  let componenteBotoes = null;
  let componentePaginacao = null;

  const handleSetPaginacao = (event) => {
    const {
      target: { value },
    } = event;

    props.handlePaginacao(value);
  };

  if (props.botoes !== null && props.botoes !== undefined) {
    componenteBotoes = props.botoes;
  }
  if (props.handlePaginacao !== null && props.handlePaginacao !== undefined) {
    componentePaginacao = (
      <Tooltip title="itens por página" >
        <Select value={props.valorPaginacao} onChange={handleSetPaginacao} autoWidth size='small' sx={{ fontSize: 11 }}>
          <MenuItem value={5} sx={{ fontSize: 11 }}>5</MenuItem>
          <MenuItem value={10} sx={{ fontSize: 11 }}>10</MenuItem>
          <MenuItem value={15} sx={{ fontSize: 11 }}>15</MenuItem>
          <MenuItem value={20} sx={{ fontSize: 11 }}>20</MenuItem>
        </Select>
      </Tooltip>
    );
  }

  return (
    <Grid container>
      <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }} xs={3}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="text.primary">
          {props.texto}
        </Typography>
      </Grid>
      <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} xs={1}>
        {componentePaginacao}
      </Grid>
      <Grid item sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} xs={8}>
        {componenteBotoes}
      </Grid>
    </Grid>
  );
}

export default Cabecalho;
