import { useEffect, useState } from "react";
import { buscarRegioes } from "../../../store/actions/index";
import { Autocomplete, TextField, Alert, Stack, Grid, Typography, Box } from "@mui/material";

function RegioesLista(props) {
  const {
    modoTela,
    tipo,
    idItemAtivo,
    dadosItemAtivo,
    setDadosItemAtivo,
    nomeAtributo,
    exibirComponenteResumo,
  } = props;

  const [carregando, setCarregando] = useState(true);
  const [dadosAlerta, setDadosAlerta] = useState({
    exibir: false,
    mensagem: "",
  });
  const [regioes, setRegioes] = useState([]);
  const [regioesSelecionadas, setRegioesSelecionadas] = useState([]);
  const [quantidadeSelecionadas, setQuantidadeSelecionadas] = useState(0);

  useEffect(() => {
    (async () => {
      if (
        modoTela === "adicionar" ||
        modoTela === "alterar" ||
        modoTela === "visualizar"
      ) {
        setCarregando(true);
        const res = await buscarRegioes();
        if (res.tipo === "SUCESSO") {
          setRegioes(res.dados);
          setCarregando(false);
        } else {
          handleExibirErro(true, res.mensagem);
        }
      }
    })();
  }, [modoTela]);

  useEffect(() => {
    if (
      idItemAtivo === null &&
      dadosItemAtivo.regioes.length === 0
    ) {
      setRegioesSelecionadas([]);
      setQuantidadeSelecionadas(0);
    } else {
      let regioesLista = regioes.filter((regiao) => {
        return (
          dadosItemAtivo.regioes.findIndex(
            (item) => item.idRegiao === regiao.id
          ) !== -1
        );
      });
      setRegioesSelecionadas([...regioesLista]);
      setQuantidadeSelecionadas(regioesLista.length);
    }
  }, [idItemAtivo, dadosItemAtivo.regioes, regioes]);

  useEffect(() => {
    setCarregando(false);
  }, [regioesSelecionadas]);

  const handleAlterarRegioesSelecionadas = (e, lista) => {
    let novoRegioes = [];
    lista.forEach((item) => {
      novoRegioes.push({
        idRegiao: item.id,
        [nomeAtributo]: dadosItemAtivo.id,
      });
    });
    setDadosItemAtivo({
      ...dadosItemAtivo,
      regioes: novoRegioes,
    });
  };

  const handleExibirErro = (exibir, mensagem) => {
    setCarregando(false);
    setDadosAlerta({ exibir: exibir, mensagem: mensagem });
  };

  let componente = null;

  if (!carregando) {
    let componenteAlerta = null;
    let componenteResumo = null;
    if (dadosAlerta.exibir) {
      componenteAlerta = (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{dadosAlerta.mensagem}</Alert>
        </Stack>
      );
    }

    if (exibirComponenteResumo === true) {
      componenteResumo = (
        <Grid item justifyContent="flex-start" alignItems="center" xs={12} px={0} py={1} sx={{ backgroundColor: "info.main" }}>
          <Typography component="div" variant="subtitle2" color="info.contrastText" px={1}>
            {quantidadeSelecionadas === 0
              ? tipo + " disponível em todas as regiões"
              : tipo + " restrito para as regiões selecionadas"}
          </Typography>
        </Grid>
      );
    }

    componente = (
      <Box sx={{ flexGrow: 1 }} p={0}>
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item justifyContent="flex-start" alignItems="center" xs={12} p={1} sx={{ backgroundColor: "primary.main" }} >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="primary.contrastText">
              Regiões
            </Typography>
          </Grid>
          <Grid item justifyContent="flex-start" alignItems="center" xs={12} px={0} >
            <Autocomplete
              multiple
              options={regioes}
              getOptionLabel={(item) => item.nome || ""}
              filterSelectedOptions
              disabled={modoTela === "visualizar"}
              renderInput={(params) => (
                <TextField {...params} />
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              value={regioesSelecionadas}
              onChange={handleAlterarRegioesSelecionadas}
              fullWidth
              ChipProps={{ style: { borderRadius: 0, fontSize: "small" } }}
              ListboxProps={{ style: { fontSize: "small" } }}
            />
          </Grid>
          {componenteResumo}
        </Grid>
        {componenteAlerta}
      </Box>
    );
  }

  return componente;
}

export default RegioesLista;
