import UsuariosDataService from "../../services/usuarios.service";

export const logarUsuario = async (data) => {
  try {
    const res = await UsuariosDataService.logar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const verificarUsuario = async (token) => {
  try {
    const res = await UsuariosDataService.verificar(token);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const deslogarUsuario = async () => {
  try {
    const res = await UsuariosDataService.deslogar();

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarUsuario = async (id) => {
  try {
    const res = await UsuariosDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarUsuarios = async (filtros) => {
  try {
    const res = await UsuariosDataService.buscarTodos(filtros);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarUsuario = async (data) => {
  try {
    const res = await UsuariosDataService.adicionar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarUsuario = async (id, data) => {
  try {
    const res = await UsuariosDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarSenhaUsuario = async (id, data) => {
  try {
    const res = await UsuariosDataService.alterarSenha(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerUsuario = async (id) => {
  try {
    const res = await UsuariosDataService.remover(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
