import { useEffect, useState } from "react";
import {
  buscarMacroRegiao,
  buscarMacroRegioes,
  adicionarMacroRegiao,
  alterarMacroRegiao,
  removerMacroRegiao,
} from "../../store/actions/index";
import { format } from "date-fns";
import Cabecalho from "../../componentes/cabecalho";
import Tabela from "../../componentes/tabela";
import RegioesLista from "../geral/regioes/RegioesLista";
import {
  ajustarDataLocal,
} from "../../bibliotecas/funcoes";
import { Tooltip, Box, Typography, IconButton, Alert, Stack, TextField, Grid, Button } from "@mui/material";
import Cached from "@mui/icons-material/Cached";
import AbcIcon from "@mui/icons-material/Abc";
import AddIcon from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const campos = [
  { key: "nome", label: "Nome", width: "45%", align: "left", fill: true },
  { key: "informacoes", label: "Informações", width: "25%", align: "center" },
  { key: "criacao", label: "Criação", width: "10%", align: "center" },
  { key: "acoes", label: "Ações", width: "20%", align: "right" },
];

const MacroRegioes = (props) => {
  const [modoTela, setModoTela] = useState(props.modoTela);
  const [dadosAlerta, setDadosAlerta] = useState({
    exibir: false,
    mensagem: "",
  });
  const [carregando, setCarregando] = useState(true);
  const [atualizarLista, setAtualizarLista] = useState(false);
  const [idItemAtivo, setIdItemAtivo] = useState(null);
  const [dadosItemAtivo, setDadosItemAtivo] = useState({
    nome: "",
    dataCriacao: null,
    regioes: [],
  });
  const [dadosTabela, setDadosTabela] = useState([]);
  const [dadosFiltrados, setDadosFiltrados] = useState([]);
  const estadoInicialFiltro = {
    nome: "",
  };
  const [filtros, setFiltros] = useState(estadoInicialFiltro);

  let conteudo = null;

  useEffect(() => {
    (async () => {
      if (modoTela === "listar") {
        setCarregando(true);
        const res = await buscarMacroRegioes();
        if (res.tipo === "SUCESSO") {
          handleCarregarLista(res.dados);
          setIdItemAtivo(null);
          setDadosItemAtivo({
            nome: "",
            regioes: [],
          });
          handleExibirErro(false, "");
          setCarregando(false);
        } else if (res.tipo === "ERRO") {
          handleExibirErro(true, res.mensagem);
        }
      }
    })();
  }, [modoTela, atualizarLista]);

  const handleChangeFiltro = (event) => {
    const { id, value } = event.target;
    let filtrosLocal = { ...filtros };

    if (id === "nome") {
      filtrosLocal = { ...filtrosLocal, nome: value };
    }
    setFiltros(filtrosLocal);
    handleFiltrarDados(dadosTabela, filtrosLocal);
  };

  const handleFiltrarDados = (dados, filtrosLocal) => {
    let itens = [...dados];
    if (filtrosLocal.nome !== "") {
      itens = (itens.filter((item) => item.nome.toUpperCase().includes(filtrosLocal.nome.toUpperCase())));
    }
    setDadosFiltrados(itens);
  };

  const handleAtualizarLista = () => {
    setAtualizarLista(!atualizarLista);
  };

  const handleExibirErro = (exibir, mensagem) => {
    setCarregando(false);
    setDadosAlerta({ exibir: exibir, mensagem: mensagem });
  };

  const handleAlterarModoTela = (modoTela) => {
    handleExibirErro(false, "");
    setModoTela(modoTela);
  };

  const handleAdicionar = async () => {
    setCarregando(true);
    const res = await adicionarMacroRegiao({
      nome: dadosItemAtivo.nome,
      regioes: dadosItemAtivo.regioes,
    });
    if (res.tipo === "SUCESSO") {
      handleAlterarModoTela("listar");
    } else if (res.tipo === "ERRO") {
      handleExibirErro(true, res.mensagem);
    }
  };

  const handleAlterar = async () => {
    setCarregando(true);
    const res = await alterarMacroRegiao(dadosItemAtivo.id, {
      nome: dadosItemAtivo.nome,
      regioes: dadosItemAtivo.regioes,
    });
    if (res.tipo === "SUCESSO") {
      handleAlterarModoTela("listar");
    } else if (res.tipo === "ERRO") {
      handleExibirErro(true, res.mensagem);
    }
  };

  const handleRemover = async (id) => {
    setCarregando(true);
    const res = await removerMacroRegiao(id);
    if (res.tipo === "SUCESSO") {
      let novosDadosTabela = [...dadosTabela];
      novosDadosTabela = novosDadosTabela.filter((item) => item.id !== id);
      setDadosTabela(novosDadosTabela);
      let novosDadosFiltrados = [...dadosFiltrados];
      novosDadosFiltrados = novosDadosFiltrados.filter((item) => item.id !== id);
      setDadosFiltrados(novosDadosFiltrados);
      setCarregando(false);
    } else if (res.tipo === "ERRO") {
      handleExibirErro(true, res.mensagem);
    }
  };

  const handleEditar = async (id) => {
    const res = await buscarMacroRegiao(id);
    if (res.tipo === "SUCESSO") {
      setIdItemAtivo(id);
      setDadosItemAtivo({
        id: res.dados.id,
        nome: res.dados.nome,
        dataCriacao: res.dados.dataCriacao,
        regioes: res.dados.regioes,
      });
      handleAlterarModoTela("alterar");
    } else if (res.tipo === "ERRO") {
      handleExibirErro(true, res.mensagem);
    }
  };

  const handlePreencher = (e) => {
    handleExibirErro(false, "");
    const { id, value } = e.target;

    switch (id) {
      case "nome":
        setDadosItemAtivo({
          ...dadosItemAtivo,
          nome: value,
        });
        break;
      default:
    }
  };

  const renderNome = ({ nome }) => {
    return (
      <Typography component="div" variant="subtitle3" color="text.primary">
        {nome}
      </Typography>
    );
  };

  const renderCriacao = ({ dataCriacao }) => {
    let dataCriacaoAjustada = ajustarDataLocal(dataCriacao);
    let strDataCriacao = format(dataCriacaoAjustada, "dd-MM-yyyy");

    return (
      <Typography component="div" variant="subtitle3" color="text.primary">
        {strDataCriacao}
      </Typography>
    );
  };

  const renderInformacoes = ({ tamanho, qtdePontosNotaveis, qtdeRegioes }) => {
    return (
      <>
        <Grid container direction="column">
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", padding: 0, justifyContent: "center", alignItems: "center" }}>
            <Typography component="div" variant="subtitle3" color="text.secondary" textAlign="center" sx={{ whiteSpace: "pre" }}>
              {"Área (km2) "}
            </Typography>
            <Typography component="div" variant="subtitle2" color="text.primary" textAlign="center">
              {tamanho ? tamanho.toFixed(2) : 0}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", padding: 0, justifyContent: "center", alignItems: "center" }}>
            <Typography component="div" variant="subtitle3" color="text.secondary" textAlign="center" sx={{ whiteSpace: "pre" }}>
              {"Regiões "}
            </Typography>
            <Typography component="div" variant="subtitle2" color="text.primary" textAlign="center">
              {qtdeRegioes ?? 0}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", padding: 0, justifyContent: "center", alignItems: "center" }}>
            <Typography component="div" variant="subtitle3" color="text.secondary" textAlign="center" sx={{ whiteSpace: "pre" }}>
              {"Pontos de Encontro "}
            </Typography>
            <Typography component="div" variant="subtitle2" color="text.primary" textAlign="center">
              {qtdePontosNotaveis ?? 0}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderAcoes = ({ id, situacao }) => {
    let acaoAlterar = ["Inativo", "Pendente"].indexOf(situacao) !== -1 ? null : (
      <Tooltip title="alterar">
        <IconButton color="secondary" onClick={() => {
          handleEditar(id);
        }}>
          <Edit />
        </IconButton>
      </Tooltip>
    );

    let acaoRemover = ["Inativo"].indexOf(situacao) !== -1 ? null : (
      <Tooltip title="remover">
        <IconButton color="secondary" onClick={() => { handleRemover(id); }}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );

    return (
      <>
        {acaoAlterar}
        {acaoRemover}
      </>
    );
  };

  const handleCarregarLista = (dados) => {
    let itens = [];
    dados.map((item) => itens.push({
      id: item.id,
      nome: item.nome,
      tamanho: item.tamanho,
      qtdePontosNotaveis: item.qtdePontosNotaveis,
      qtdeRegioes: item.qtdeRegioes,
      situacao: item.situacao,
      dataCriacao: item.dataCriacao,
    }));
    setDadosTabela(itens);
    handleFiltrarDados(itens, filtros);
  };

  const renderItem = (item) => {
    return ({
      nome: renderNome({ nome: item.nome }),
      criacao: renderCriacao({ dataCriacao: item.dataCriacao }),
      informacoes: renderInformacoes({ tamanho: item.tamanho, qtdePontosNotaveis: item.qtdePontosNotaveis, qtdeRegioes: item.qtdeRegioes }),
      acoes: renderAcoes({ id: item.id, situacao: item.situacao }),
    });
  };

  if (!carregando) {
    let componenteAlerta = null;

    if (dadosAlerta.exibir) {
      componenteAlerta = (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">{dadosAlerta.mensagem}</Alert>
        </Stack>
      );
    }

    if (modoTela === "listar") {
      let componenteBotoes = (
        <>
          <Tooltip title="adicionar nova Macro Região">
            <IconButton color="secondary" onClick={() => handleAlterarModoTela("adicionar")} >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="atualizar lista">
            <IconButton color="secondary" onClick={handleAtualizarLista} >
              <Cached />
            </IconButton>
          </Tooltip>
        </>
      );

      conteudo = (
        <Tabela
          textoCabecalho={"Listar Regiões"}
          botoesCabecalho={componenteBotoes}
          restritoEmpresa={false}
          itens={dadosFiltrados}
          campos={campos}
          alerta={dadosAlerta}
          renderItem={renderItem}
          //nome, grupos, informacoes, criacao, acoes
          filtros={[
            <TextField
              id="nome"
              autoComplete="off"
              autoCorrect="off"
              fullWidth
              type="text"
              value={filtros.nome}
              onChange={handleChangeFiltro}
              InputProps={{
                style: { fontSize: 11 },
              }}
              size="small"
            />,
            <></>,
            <></>,
            <></>,
            <></>,
          ]}
        />
      );
    } else if (modoTela === "adicionar" || modoTela === "alterar") {
      if (dadosItemAtivo !== null) {

        conteudo = (
          <Box sx={{ display: "grid", gridTemplateRows: "auto auto 1fr auto auto" }}>
            <Box sx={{ flexGrow: 1, padding: 1, display: "flex", alignItems: "center" }}>
              <Cabecalho texto={modoTela === "adicionar"
                ? "Adicionar Macro Região"
                : "Alterar Macro Região"} />
            </Box>
            <Box sx={{ flexGrow: 1, padding: 1 }}>

              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Box sx={{ backgroundColor: "primary.main" }}>
                  <Tooltip title="nome da região">
                    <AbcIcon sx={{ color: "primary.contrastText", mx: 1, my: 0.5 }} />
                  </Tooltip>
                </Box>
                <TextField
                  id="nome"
                  type="text"
                  variant="standard"
                  placeholder="nome"
                  autoComplete="off"
                  autoCorrect="off"
                  autoFocus
                  fullWidth
                  value={dadosItemAtivo.nome}
                  onChange={handlePreencher}
                  InputProps={{
                    style: { fontSize: "small", p: 0 },
                  }}
                  sx={{ pl: 1 }}
                />
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <RegioesLista
                  tipo="Macro Região"
                  modoTela={modoTela}
                  idItemAtivo={idItemAtivo}
                  dadosItemAtivo={dadosItemAtivo}
                  setDadosItemAtivo={setDadosItemAtivo}
                  nomeAtributo="idMacroRegiao"
                />
              </Box>

            </Box>
            <Box sx={{ flexGrow: 1 }}>
              {componenteAlerta}
            </Box>
            <Box sx={{ flexGrow: 1, padding: 1 }}>
              <Grid container direction="row">
                <Grid item justifyContent="flex-end" alignItems="center" xs={12}>
                  <Grid sx={{ justifyContent: "flex-end", display: "flex", alignItems: "center" }}>
                    <Button variant="contained" sx={{ fontSize: 11, mr: 1 }} size="small" color="primary"
                      startIcon={<CheckIcon />}
                      onClick={() => {
                        modoTela === "adicionar"
                          ? handleAdicionar()
                          : handleAlterar();
                      }}
                      disabled={
                        dadosItemAtivo.regioes.length === 0 ||
                        dadosItemAtivo.nome === ""
                      }
                    >
                      Confirmar
                    </Button>
                    <Button variant="contained" sx={{ fontSize: 11 }} size="small" color="primary"
                      startIcon={<ClearIcon />}
                      onClick={() => handleAlterarModoTela("listar")}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box >
        );
      }
    }
  }

  return conteudo;
};

export default MacroRegioes;
