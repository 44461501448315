import mime from 'mime';
import { Typography, Link } from "@mui/material";

function ajustarDataLocal(valor) {
  let data = new Date(valor);
  //data.setTime(data.getTime());
  data.setTime(data.getTime() + data.getTimezoneOffset() * 60 * 1000);
  return data;
}

function verificarPermissao(permissoes, usuarioLogado, nomePermissao) {
  try {
    //Usuários internos ou sem restrição não precisam de verificação adicional.
    if (usuarioLogado.permissoes.length === 0 || usuarioLogado.ehAdmin) {
      return true;
    }

    if (permissoes === undefined || permissoes === null) {
      permissoes = [];
    }

    let permissao = permissoes.filter(
      (permissao) => permissao.nome === nomePermissao
    );
    permissao = permissao.length > 0 ? permissao[0] : null;

    if (permissao !== null) {
      return (
        usuarioLogado.permissoes.findIndex(
          (itemPermissao) => itemPermissao.idPermissao === permissao.id
        ) !== -1
      );
    } else {
      return false;
    }
  } catch (erro) {
    throw erro;
  }
}

const handleRetornarTipoAnexo = (url) => {
  let tipo = "";
  const mimeType = mime.getType(url);

  if (mimeType === "image/jpeg" || mimeType === "image/png") {
    tipo = "imagem";
  } else if (
    mimeType === "video/x-flv" ||
    mimeType === "video/mp4" ||
    mimeType === "application/x-mpegURL" ||
    mimeType === "video/MP2T" ||
    mimeType === "video/3gpp" ||
    mimeType === "video/quicktime" ||
    mimeType === "video/x-msvideo" ||
    mimeType === "video/x-ms-wmv"
  ) {
    /*
      Extension	MIME
      flv	video/x-flv
      mp4	video/mp4
      m3u8	application/x-mpegURL
      ts	video/MP2T
      3gp	video/3gpp
      mov	video/quicktime
      avi	video/x-msvideo
      wmv	video/x-ms-wmv
    */
    tipo = "video";
  } else if (
    mimeType === "audio/basic" ||
    mimeType === "audio/L24" ||
    mimeType === "audio/mid" ||
    mimeType === "audio/mpeg" ||
    mimeType === "audio/aac" ||
    mimeType === "audio/x-aac" ||
    mimeType === "audio/mp4" ||
    mimeType === "audio/x-aiff" ||
    mimeType === "audio/x-mpegurl" ||
    mimeType === "audio/vnd.rn-realaudio" ||
    mimeType === "audio/ogg" ||
    mimeType === "audio/vorbis" ||
    mimeType === "audio/vnd.wav"
  ) {
    /*
      Extension	MIME
      au	audio/basic
      snd	audio/basic	 
      mid	audio/mid	 
      rmi	audio/mid	 
      mp3	audio/mpeg
      aac audio/aac
      mp4 audio	audio/mp4	 
      aif	audio/x-aiff	 
      aifc	audio/x-aiff	 
      aiff	audio/x-aiff	 
      m3u	audio/x-mpegurl	 
      ra	audio/vnd.rn-realaudio	 
      ram	audio/vnd.rn-realaudio	 
      Ogg Vorbis	audio/ogg
      Vorbis	audio/vorbis
      wav	audio/vnd.wav
    */
    tipo = "audio";
  }

  return tipo;
};

const renderCopyright = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", whiteSpace: "pre" }}>
      <Typography variant="body2" color="#fff">
        <Link
          href="https://www.htiltda.com/"
          target="_blank"
          color="#fff"
          sx={{ textDecoration: "none" }}
        >
          {"Comunica "}
        </Link>
      </Typography>
      <Typography variant="body3" color="#fff" sx={{ fontWeight: "100" }}>
        &copy; {new Date().getFullYear()} -
      </Typography>
      <Typography variant="body2" color="#fff">
        {"v." + process.env.REACT_APP_VERSION}
      </Typography>
    </div>
  );
};

export {
  ajustarDataLocal,
  verificarPermissao,
  handleRetornarTipoAnexo,
  renderCopyright,
};
