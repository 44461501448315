import axios from "axios";

let baseURL = "";

if (process.env.NODE_ENV === "production") {
  baseURL =
    (process.env.REACT_APP_PROTOCOL === "HTTPS"
      ? "https://"
      : "http://" || "http://") +
    (process.env.REACT_APP_HOST_SERVER || "localhost");
} else {
  baseURL =
    (process.env.REACT_APP_PROTOCOL === "HTTPS"
      ? "https://"
      : "http://" || "http://") +
    (process.env.REACT_APP_HOST_SERVER + ":" || "localhost:") +
    (process.env.REACT_APP_PORT_SERVER || "8080");
}

//TODO:Desfazer
// Rodar web local com server remoto;
// baseURL =
//   (process.env.REACT_APP_PROTOCOL === "HTTPS"
//     ? "https://"
//     : "http://" || "http://") +
//   (process.env.REACT_APP_HOST_SERVER || "localhost");
//Rodar web local com server local
// baseURL =
//   (process.env.REACT_APP_PROTOCOL === "HTTPS"
//     ? "https://"
//     : "http://" || "http://") +
//   ("127.0.0.1" + ":" || "localhost:") +
//   ("8080" || "8080");

const http = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, PUT, PATCH, GET, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "*",
  },
});

export default http;
