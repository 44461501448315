import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class PermissoesDataService {
  buscarTodas() {
    return http.get(`/permissoes/funcoes/`, { headers: authHeader() });
  }
}

const permissoesDataService = new PermissoesDataService();

export default permissoesDataService;
