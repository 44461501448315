import { Suspense } from "react";
import "./scss/style.scss";
import Composicao from "../src/containers/Composicao";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  let componente = (
    <Suspense fallback={loading}>
      <Composicao />
    </Suspense>
  );

  return componente;
};

export default App;
