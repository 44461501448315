import { memo, useState } from "react";
import { Stack, Alert, Typography, TablePagination } from "@mui/material";
import { Scrollbar } from 'react-scrollbars-custom';
import { useAtomValue } from "jotai";
import { empresaAtivaAtom } from "../store";

function Tabela(props) {
  let componente = null;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const empresaAtiva = useAtomValue(empresaAtivaAtom);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let exibirAlerta = props.alerta === undefined ? false : props.alerta.exibir;
  let componenteAlerta = null;

  const renderFill = () => {
    return (<div style={{ display: "flex", flex: "1 1 60%" }} />);
  };

  const renderNomeTabela = (nome) => {
    return (
      <div style={{ display: "flex", flex: "0 0 20%", alignItems: "center", justifyContent: "flex-start" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="text.primary">
          {nome}
        </Typography>
      </div>
    );
  };

  const renderAcoesTabela = (acoes) => {
    return (
      <div style={{ display: "flex", flex: "0 0 20%", alignItems: "center", justifyContent: "flex-end" }}>
        {acoes}
      </div>
    );
  };

  const renderVazio = () => {
    let mensagem = "";

    if ((props.restritoEmpresa === undefined || props.restritoEmpresa === true) && (empresaAtiva.id === null)) {
      mensagem = "nenhuma empresa selecionada";
    } else {
      mensagem = "nenhum item encontrado";
    }

    return (
      <div style={{ display: "flex", flex: "1", alignItems: "center", justifyContent: "flex-start", padding: "1%" }}>
        <Stack sx={{ width: "100%", height: "100%" }} spacing={2}>
          <Alert severity="info">{mensagem}</Alert>
        </Stack>
      </div>
    );
  };

  if (exibirAlerta) {
    componenteAlerta = (
      <Stack sx={{ width: "100%", height: "100%" }} spacing={2}>
        <Alert severity="error">{props.alerta.mensagem}</Alert>
      </Stack>
    );
  }

  componente = (
    <div style={{ display: "flex", height: "100%", flexDirection: "column", paddingRight: "1%", paddingLeft: "1%" }}>
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <div style={{ display: "flex", flexDirection: "row", paddingTop: 5, paddingBottom: 5 }}>
          {renderNomeTabela(props.textoCabecalho)}
          {renderFill()}
          {renderAcoesTabela(props.botoesCabecalho)}
        </div>
        <div style={{ display: "flex", flexDirection: "row", paddingTop: 5, paddingBottom: 5 }}>
          {props.campos.map((column) => {
            const valorWidth = column.fill ? "1 1 " + column.width : "0 1 " + column.width;
            return (
              <div
                key={column.key}
                align={column.align}
                style={{ flex: valorWidth, paddingLeft: "0.5%", paddingRight: "0.5%" }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }} color="text.primary">
                  {column.label}
                </Typography>
              </div>
            );
          })}
        </div>
        <div style={{ display: "flex", flexDirection: "row", paddingTop: 5, paddingBottom: 5, borderBottom: "0.5px solid rgba(60, 56, 52, 0.5)" }}>
          {props.campos.map((column, index) => {
            const valorWidth = column.fill ? "1 1 " + column.width : "0 1 " + column.width;
            return (
              <div
                key={column.key}
                align={column.align}
                style={{ flex: valorWidth, paddingLeft: "0.5%", paddingRight: "0.5%" }}
              >
                {props.filtros[index]}
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ display: "flex", flex: 8.5, flexDirection: "column", position: "relative", overflowY: "auto" }} >
        <Scrollbar style={{ width: "100%", height: "100%" }}>
          {(props.itens.length === 0)
            ? renderVazio()
            : props.itens
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <div key={row.id} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", paddingTop: 10, paddingBottom: 10, borderBottom: "0.5px solid rgba(60, 56, 52, 0.25)" }}>
                    {props.campos.map((column) => {
                      const valorWidth = column.fill ? "1 1 " + column.width : "0 1 " + column.width;
                      return (
                        <div key={column.key} align={column.align} style={{ flex: valorWidth, paddingLeft: "0.5%", paddingRight: "0.5%" }}>
                          {props.renderItem(row)[column.key]}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
        </Scrollbar>
      </div>
      <div style={{ display: "flex", flex: 0.5, flexDirection: "column" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          labelRowsPerPage="Itens por página"
          labelDisplayedRows={(info) => {
            return (info.from + "-" + info.to + " de " + info.count);
          }}
          component="div"
          count={props.itens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      {componenteAlerta}
    </div >
  );

  return componente;
}

export default memo(Tabela);
