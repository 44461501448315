import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class TiposOcorrenciaDataService {
  buscarTodos(filtros) {
    return http.get(`/tiposocorrencia/${filtros}`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/tipoocorrencia/${id}`, { headers: authHeader() });
  }

  adicionar(data) {
    return http.post(`/tipoocorrencia`, data, { headers: authHeader() });
  }

  alterar(id, data) {
    return http.put(`/tipoocorrencia/${id}`, data, { headers: authHeader() });
  }

  reativar(id) {
    return http.put(`/tipoocorrencia/reativar/${id}`, null, {
      headers: authHeader(),
    });
  }

  desativar(id) {
    return http.put(`/tipoocorrencia/desativar/${id}`, null, {
      headers: authHeader(),
    });
  }

  remover(id) {
    return http.delete(`/tipoocorrencia/${id}`, { headers: authHeader() });
  }
}

const tiposOcorrenciaDataService = new TiposOcorrenciaDataService();

export default tiposOcorrenciaDataService;