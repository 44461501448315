import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class UsuariosDataService {
  logar(data) {
    return http.post(`/logar`, data);
  }

  verificar(token) {
    return http.get(`/verificar/${token}`);
  }

  deslogar() {
    return http.post(`/deslogar`);
  }

  buscarTodos(filtros) {
    return http.get(`/usuarios/${filtros}`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/usuario/${id}`, { headers: authHeader() });
  }

  adicionar(data) {
    return http.post(`/usuario`, data, { headers: authHeader() });
  }

  alterar(id, data) {
    return http.put(`/usuario/${id}`, data, { headers: authHeader() });
  }

  alterarSenha(id, data) {
    return http.put(`/alterarsenha/${id}`, data);
  }

  remover(id) {
    return http.delete(`/usuario/${id}`, { headers: authHeader() });
  }
}

const usuariosDataService = new UsuariosDataService();

export default usuariosDataService;


