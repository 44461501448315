import MacroRegioesDataService from "../../services/macroRegioes.service";

export const buscarMacroRegiao = async (id) => {
  try {
    const res = await MacroRegioesDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarMacroRegioes = async () => {
  try {
    const res = await MacroRegioesDataService.buscarTodas();

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarMacroRegiao = async (data) => {
  try {
    const res = await MacroRegioesDataService.adicionar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarMacroRegiao = async (id, data) => {
  try {
    const res = await MacroRegioesDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerMacroRegiao = async (id) => {
  try {
    const res = await MacroRegioesDataService.remover(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
