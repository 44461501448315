import PesquisasDataService from "../../services/pesquisas.service";

export const buscarPesquisas = async (filtros) => {
  try {
    const res = await PesquisasDataService.buscarTodas(filtros);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarPesquisa = async (id) => {
  try {
    const res = await PesquisasDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarPesquisa = async (data) => {
  try {
    const res = await PesquisasDataService.adicionar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarPesquisa = async (id, data) => {
  try {
    const res = await PesquisasDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const reativarPesquisa = async (id) => {
  try {
    const res = await PesquisasDataService.reativar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const desativarPesquisa = async (id) => {
  try {
    const res = await PesquisasDataService.desativar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerPesquisa = async (id) => {
  try {
    const res = await PesquisasDataService.remover(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
