import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class SistemaDataService {
  buscarConfiguracao(nome) {
    return http.get(`/sistema/configuracao/${nome}`);
  }

  buscarData() {
    return http.get(`/sistema/data`, { headers: authHeader() });
  }

  buscarEstatisticas(filtros) {
    return http.get(`/sistema/estatisticas/${filtros}`, { headers: authHeader() });
  }
}

const sistemaDataService = new SistemaDataService();

export default sistemaDataService;

