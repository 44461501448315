import { useState } from "react";
import Avatar from "react-avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useAtomValue } from "jotai";
import { usuarioAtom } from "../store";

const MenuPerfil = ({
  handleDeslogar
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const usuario = useAtomValue(usuarioAtom);
  const [menuAberto, setMenuAberto] = useState(false);

  const handleAbrirMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuAberto(true);
  };

  const handleFecharMenu = () => {
    setAnchorEl(null);
    setMenuAberto(false);
  };

  const handleSair = () => {
    handleDeslogar();
  };

  let componente = (
    <Box>
      <IconButton onClick={handleAbrirMenu} sx={{ p: 0 }}>
        <Avatar
          color="rgba(210, 157, 42, 1)"
          name={usuario.nome}
          size={40}
          round
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        sx={{ mt: '45px', width: "200" }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={menuAberto}
        onClose={handleFecharMenu}
      >
        <MenuItem onClick={handleSair}>
          <Typography textAlign="center">Sair</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );

  return componente;
};

export default MenuPerfil;
