import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class PesquisasDataService {
  buscarTodas(filtros) {
    return http.get(`/pesquisas/${filtros}`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/pesquisa/${id}`, { headers: authHeader() });
  }

  adicionar(data) {
    return http.post(`/pesquisa`, data, { headers: authHeader() });
  }

  alterar(id, data) {
    return http.put(`/pesquisa/${id}`, data, { headers: authHeader() });
  }

  reativar(id) {
    return http.put(`/pesquisa/reativar/${id}`, null, {
      headers: authHeader(),
    });
  }

  desativar(id) {
    return http.put(`/pesquisa/desativar/${id}`, null, {
      headers: authHeader(),
    });
  }

  remover(id) {
    return http.delete(`/pesquisa/${id}`, { headers: authHeader() });
  }
}

const pesquisasDataService = new PesquisasDataService();

export default pesquisasDataService;