import PermissoesDataService from "../../services/permissoes.service";

export const buscarPermissoes = async () => {
  try {
    const res = await PermissoesDataService.buscarTodas();

    return (res.data);
  } catch (err) {
    return (err);
  }
};
