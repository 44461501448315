import DemandasDataService from "../../services/demandas.service";

export const buscarDemandas = async (filtros) => {
  try {
    const res = await DemandasDataService.buscarTodas(filtros);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarDemanda = async (id) => {
  try {
    const res = await DemandasDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarDemanda = async (data) => {
  try {
    const res = await DemandasDataService.adicionar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarDemanda = async (id, data) => {
  try {
    const res = await DemandasDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerDemanda = async (id) => {
  try {
    const res = await DemandasDataService.remover(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarConversaDemanda = async (data) => {
  try {
    const res = await DemandasDataService.adicionarConversa(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerConversaDemanda = async (dados) => {
  try {
    const res = await DemandasDataService.removerConversa(dados);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
