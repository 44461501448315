import { useEffect, useState } from "react";
import { MenuItem, Select, Typography } from "@mui/material";
import { empresaAtivaAtom, empresasAtom, atualizarMenuEmpresasAtom, empresasManuaisAtom, usuarioAtom } from "../store";
import { useAtom, useAtomValue } from "jotai";
import { handleGerarUri } from "../bibliotecas/buckets";

const MenuEmpresa = () => {
  const [carregando, setCarregando] = useState(true);
  const [urisImagem, setUrisImagem] = useState([]);
  const empresas = useAtomValue(empresasAtom);
  const empresasManuais = useAtomValue(empresasManuaisAtom);
  const usuario = useAtomValue(usuarioAtom);
  const atualizarMenuEmpresas = useAtomValue(atualizarMenuEmpresasAtom);
  const [empresaAtiva, setEmpresaAtiva] = useAtom(empresaAtivaAtom);

  useEffect(() => {
    (async () => {
      setCarregando(true);
      let novoUrisImagem = [];
      await Promise.all(empresas.map(async (empresa) => {
        if (empresa.ativo) {
          let uri = await handleGerarUri(empresa.imagem, "empresas", "anexos/empresas");
          novoUrisImagem.push(uri ?? "");
        }
      }));
      setUrisImagem(novoUrisImagem);
      setCarregando(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setCarregando(true);
      let novoUrisImagem = [];
      await Promise.all(empresas.map(async (empresa) => {
        if (empresa.ativo) {
          let uri = await handleGerarUri(empresa.imagem, "empresas", "anexos/empresas");
          novoUrisImagem.push(uri ?? "");
        }
      }));
      setUrisImagem(novoUrisImagem);
      setCarregando(false);
    })();
  }, [atualizarMenuEmpresas]);

  const handleAlterarEmpresa = (id) => {
    let valor = (id === -9999) ? null : id;
    setEmpresaAtiva({ id: valor });
  };

  let componente = null;

  if (!carregando) {
    let listaEmpresas = [];

    if (empresas.length > 1) {
      empresas.forEach((empresa, indice) => {
        if (empresa.ativo) {
          listaEmpresas.push({ id: empresa.id, nome: empresa.nome, uri: urisImagem[indice] });
        }
      });
    }

    if (usuario.idEmpresa === null && usuario.ehAdmin && empresasManuais.length > 0) {
      listaEmpresas.push({ id: null, nome: "não cadastradas", uri: null });
    }

    componente = (
      <Select value={(empresaAtiva.id === null) ? -9999 : empresaAtiva.id} autoWidth size='small' onChange={(e) => { handleAlterarEmpresa(e.target.value); }} sx={{ width: "100%", height: "75%", fontSize: 11, backgroundColor: "#fff" }}>
        {listaEmpresas.map((item) => {
          let componenteImagem = null;

          if (item.uri) {
            componenteImagem = (
              <img
                src={item.uri}
                width="32"
                style={{ objectFit: "contain" }}
                alt="logo_empresa"
              />
            );
          }

          return (<MenuItem key={(item.id === null) ? -9999 : item.id} value={(item.id === null) ? -9999 : item.id}>
            <div style={{ fontSize: 11, display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
              {componenteImagem}
              <Typography variant="subtitle2" color="text.primary" sx={{ pl: 1 }}>
                {item.nome}
              </Typography>
            </div>
          </MenuItem>);
        })}
      </Select>
    );
  }

  return componente;
};

export default MenuEmpresa;
