import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class MoradoresDataService {
  buscarTodos(filtros) {
    return http.get(`/moradores/${filtros}`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/morador/${id}`, { headers: authHeader() });
  }

  alterar(id, data) {
    return http.put(`/morador/${id}`, data, { headers: authHeader() });
  }

  remover(id) {
    return http.delete(`/morador/${id}`, { headers: authHeader() });
  }
}

const moradoresDataService = new MoradoresDataService();

export default moradoresDataService;
