import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from '@mui/material/ListItem';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import QuizIcon from '@mui/icons-material/Quiz';
import GradeIcon from '@mui/icons-material/Grade';
import PeopleIcon from '@mui/icons-material/People';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CircleIcon from '@mui/icons-material/Circle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BusinessIcon from '@mui/icons-material/Business';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { Divider } from "@mui/material";
import { useAtomValue } from "jotai";
import { ehUsuarioMasterAtom, usuarioAtom, permissoesAtom, empresaAtivaAtom } from "../store";
import { useLocation } from "react-router-dom";
import { verificarPermissao } from "../bibliotecas/funcoes";

function Navbar() {
    const { pathname } = useLocation();
    const usuario = useAtomValue(usuarioAtom);
    const ehUsuarioMaster = useAtomValue(ehUsuarioMasterAtom);
    const permissoes = useAtomValue(permissoesAtom);
    const empresaAtiva = useAtomValue(empresaAtivaAtom);

    const renderSecaoMenu = ({ label }) => {
        return (
            <>
                <Divider />
                <ListItem
                    key={label}
                    sx={{ display: "flex", minHeight: 48, justifyContent: 'initial', pr: 3.5, backgroundColor: "rgba(60, 56, 52, 1)" }}
                >
                    <ListItemText primary={label} primaryTypographyProps={{ variant: "body2", color: "white" }} />
                </ListItem>
                <Divider />
            </>
        );
    };

    const renderBotaoMenu = ({ label, icone, link, habilitado }) => {
        return (
            <ListItemButton
                disabled={!habilitado}
                component={Link}
                to={link}
                key={label}
                selected={pathname === link}
                sx={{ minHeight: 48, justifyContent: 'initial', pr: 3.5 }}
            >
                <ListItemIcon sx={{ minWidth: 0, mr: 1, justifyContent: 'center' }}>
                    {icone}
                </ListItemIcon>
                <ListItemText primary={label} primaryTypographyProps={{ variant: "body2", color: "secondary" }} />
            </ListItemButton>
        );
    };

    let rotaPremiacoes = null;
    let secaoCadastrosEspaciais = null;
    let rotaMacroRegioes = null;
    let rotaRegioes = null;

    if (usuario.usarPremiacoes) {
        rotaPremiacoes = renderBotaoMenu({ label: "Premiações", habilitado: verificarPermissao(permissoes, usuario, "PREMIACOES"), link: "/premiacoes", icone: <GradeIcon color={pathname === "/premiacoes" ? "warning" : "primary"} /> });
    }

    if (ehUsuarioMaster === true) {
        secaoCadastrosEspaciais = renderSecaoMenu({ label: "Cadastros Espaciais" });
        rotaMacroRegioes = renderBotaoMenu({ label: "Macro Regiões", habilitado: true, link: "/macroRegioes", icone: <WorkspacesIcon color={pathname === "/macroRegioes" ? "warning" : "primary"} /> });
        rotaRegioes = renderBotaoMenu({ label: "Regiões", habilitado: true, link: "/regioes", icone: <CircleIcon color={pathname === "/regioes" ? "warning" : "primary"} /> });
    }

    return (
        <List sx={{ display: "flex", flex: 1, flexDirection: "column", p: 0 }}>
            {renderBotaoMenu({ label: "Painel", habilitado: true, link: "/", icone: <DashboardIcon color={pathname === "/" ? "warning" : "primary"} /> })}
            {renderSecaoMenu({ label: "Relacionamento" })}
            {renderBotaoMenu({ label: "Contatos", habilitado: verificarPermissao(permissoes, usuario, "CONTATOS"), link: "/contatos", icone: <ChatBubbleIcon color={pathname === "/contatos" ? "warning" : "primary"} /> })}
            {renderBotaoMenu({ label: "Moradores", habilitado: verificarPermissao(permissoes, usuario, "MORADORES"), link: "/moradores", icone: <HomeIcon color={pathname === "/moradores" ? "warning" : "primary"} /> })}
            {renderSecaoMenu({ label: "Ações" })}
            {renderBotaoMenu({ label: "Enquetes", habilitado: verificarPermissao(permissoes, usuario, "ENQUETES"), link: "/enquetes", icone: <QuizIcon color={pathname === "/enquetes" ? "warning" : "primary"} /> })}
            {rotaPremiacoes}
            {renderSecaoMenu({ label: "Cadastros" })}
            {renderBotaoMenu({ label: "Usuários", habilitado: verificarPermissao(permissoes, usuario, "USUÁRIOS"), link: "/usuarios", icone: <PeopleIcon color={pathname === "/usuarios" ? "warning" : "primary"} /> })}
            {renderBotaoMenu({ label: "Empresas", habilitado: verificarPermissao(permissoes, usuario, "EMPRESAS"), link: "/empresas", icone: <BusinessIcon color={pathname === "/empresas" ? "warning" : "primary"} /> })}
            {renderBotaoMenu({ label: "Tipos de Contato", habilitado: verificarPermissao(permissoes, usuario, "TIPOS_OCORRENCIA"), link: "/tiposOcorrencia", icone: <ChecklistIcon color={pathname === "/tiposOcorrencia" ? "warning" : "primary"} /> })}
            {secaoCadastrosEspaciais}
            {rotaMacroRegioes}
            {rotaRegioes}
            {renderSecaoMenu({ label: "Informações" })}
            {renderBotaoMenu({ label: "Ajuda", habilitado: true, link: "/ajuda", icone: <QuestionMarkIcon color={pathname === "/ajuda" ? "warning" : "primary"} /> })}
        </List>
    );
}

export default Navbar;
