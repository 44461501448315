import http from "../http-common";
import httpForm from "../http-commonForm";
import authHeader from "../bibliotecas/authHeader";

class EmpresasDataService {
  buscarTodas(filtros) {
    return http.get(`/empresas/${filtros}`, { headers: authHeader() });
  }

  buscarTodasManuais() {
    return http.get(`/empresasManuais`, { headers: authHeader() });
  }

  buscarTodasRegiao() {
    return http.get(`/empresasRegiao`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/empresa/${id}`, { headers: authHeader() });
  }

  adicionar(data) {
    return httpForm.post(`/empresa`, data, { headers: authHeader() });
  }

  alterar(id, data) {
    return httpForm.put(`/empresa/${id}`, data, { headers: authHeader() });
  }

  reativar(id) {
    return http.put(`/empresa/reativar/${id}`, null, {
      headers: authHeader(),
    });
  }

  desativar(id) {
    return http.put(`/empresa/desativar/${id}`, null, {
      headers: authHeader(),
    });
  }

  remover(dados) {
    return http.delete(`/empresa/${dados}`, { headers: authHeader() });
  }
}

const empresasDataService = new EmpresasDataService();

export default empresasDataService;