import RegioesDataService from "../../services/regioes.service";

export const buscarRegiao = async (id) => {
  try {
    const res = await RegioesDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarRegioes = async () => {
  try {
    const res = await RegioesDataService.buscarTodas();

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarRegiao = async (data) => {
  try {
    const res = await RegioesDataService.adicionar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarRegiao = async (id, data) => {
  try {
    const res = await RegioesDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerRegiao = async (id) => {
  try {
    const res = await RegioesDataService.remover(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
