import PremiacoesDataService from "../../services/premiacoes.service";

export const buscarPremiacao = async (id) => {
  try {
    const res = await PremiacoesDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarPremiacoes = async (filtros) => {
  try {
    const res = await PremiacoesDataService.buscarTodos(filtros);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarPremiacao = async (data) => {
  try {
    const res = await PremiacoesDataService.adicionar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarPremiacao = async (id, data) => {
  try {
    const res = await PremiacoesDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerPremiacao = async (id) => {
  try {
    const res = await PremiacoesDataService.remover(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
