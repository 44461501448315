import EmpresasDataService from "../../services/empresas.service";

export const buscarEmpresas = async (filtros) => {
  try {
    const res = await EmpresasDataService.buscarTodas(filtros);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarEmpresasManuais = async () => {
  try {
    const res = await EmpresasDataService.buscarTodasManuais();

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarEmpresasRegiao = async () => {
  try {
    const res = await EmpresasDataService.buscarTodasRegiao();

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarEmpresa = async (id) => {
  try {
    const res = await EmpresasDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarEmpresa = async (data) => {
  try {
    const res = await EmpresasDataService.adicionar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarEmpresa = async (id, data) => {
  try {
    const res = await EmpresasDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const reativarEmpresa = async (id) => {
  try {
    const res = await EmpresasDataService.reativar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const desativarEmpresa = async (id) => {
  try {
    const res = await EmpresasDataService.desativar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerEmpresa = async (id, data) => {
  try {
    const res = await EmpresasDataService.remover(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

