import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class PremiacoesDataService {
  buscarTodos(filtros) {
    return http.get(`/premiacoes/${filtros}`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/premiacao/${id}`, { headers: authHeader() });
  }

  adicionar(data) {
    return http.post(`/premiacao`, data, { headers: authHeader() });
  }

  alterar(id, data) {
    return http.put(`/premiacao/${id}`, data, { headers: authHeader() });
  }

  remover(id) {
    return http.delete(`/premiacao/${id}`, { headers: authHeader() });
  }
}

const premiacoesDataService = new PremiacoesDataService();

export default premiacoesDataService;