import SistemaDataService from "../../services/sistema.service";

export const buscarConfiguracaoSistema = async (nome) => {
  try {
    const res = await SistemaDataService.buscarConfiguracao(nome);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarDataSistema = async () => {
  try {
    const res = await SistemaDataService.buscarData();

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarEstatisticasSistema = async (filtro) => {
  try {
    const res = await SistemaDataService.buscarEstatisticas(filtro);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
