import TiposOcorrenciaDataService from "../../services/tiposOcorrencia.service";

export const buscarTiposOcorrencia = async (filtros) => {
  try {
    const res = await TiposOcorrenciaDataService.buscarTodos(filtros);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarTipoOcorrencia = async (id) => {
  try {
    const res = await TiposOcorrenciaDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const adicionarTipoOcorrencia = async (data) => {
  try {
    const res = await TiposOcorrenciaDataService.adicionar(data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarTipoOcorrencia = async (id, data) => {
  try {
    const res = await TiposOcorrenciaDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const reativarTipoOcorrencia = async (id) => {
  try {
    const res = await TiposOcorrenciaDataService.reativar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const desativarTipoOcorrencia = async (id) => {
  try {
    const res = await TiposOcorrenciaDataService.desativar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerTipoOcorrencia = async (id) => {
  try {
    const res = await TiposOcorrenciaDataService.remover(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
