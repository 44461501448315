import "core-js";
import "./polyfill";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(60, 56, 52, 1)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#3c4b64",
      contrastText: "#fff",
    },
    success: {
      main: "#2eb85c",
      contrastText: "#fff",
    },
    info: {
      main: "#888",
      contrastText: "#fff",
    },
    warning: {
      main: "rgba(210, 157, 42, 1)",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
    ].join(","),
    subtitle1: {
      fontSize: 14,
    },
    subtitle2: {
      fontSize: 12,
    },
    subtitle3: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
  },
});

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
