import MoradoresDataService from "../../services/moradores.service";

export const buscarMorador = async (id) => {
  try {
    const res = await MoradoresDataService.buscar(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const buscarMoradores = async (filtros) => {
  try {
    const res = await MoradoresDataService.buscarTodos(filtros);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const alterarMorador = async (id, data) => {
  try {
    const res = await MoradoresDataService.alterar(id, data);

    return (res.data);
  } catch (err) {
    return (err);
  }
};

export const removerMorador = async (id) => {
  try {
    const res = await MoradoresDataService.remover(id);

    return (res.data);
  } catch (err) {
    return (err);
  }
};
