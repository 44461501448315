import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class MacroRegioesDataService {
  buscarTodas() {
    return http.get(`/macroregioes`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/macroregiao/${id}`, { headers: authHeader() });
  }

  adicionar(data) {
    return http.post(`/macroregiao`, data, { headers: authHeader() });
  }

  alterar(id, data) {
    return http.put(`/macroregiao/${id}`, data, { headers: authHeader() });
  }

  remover(id) {
    return http.delete(`/macroregiao/${id}`, { headers: authHeader() });
  }
}

const macroRegioesDataService = new MacroRegioesDataService();

export default macroRegioesDataService;
