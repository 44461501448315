import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class RegioesDataService {
  buscarTodas() {
    return http.get(`/regioes`, { headers: authHeader() });
  }

  buscar(id) {
    return http.get(`/regiao/${id}`, { headers: authHeader() });
  }

  adicionar(data) {
    return http.post(`/regiao`, data, { headers: authHeader() });
  }

  alterar(id, data) {
    return http.put(`/regiao/${id}`, data, { headers: authHeader() });
  }

  remover(id) {
    return http.delete(`/regiao/${id}`, { headers: authHeader() });
  }
}

const regioesDataService = new RegioesDataService();

export default regioesDataService;
