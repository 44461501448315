import { useEffect, useState, memo, lazy } from "react";
import { createBrowserRouter, RouterProvider, Outlet, useRouteError } from "react-router-dom";
import { buscarEmpresas, verificarUsuario, deslogarUsuario, buscarEmpresasManuais } from "../store/actions/index";
import { useJsApiLoader } from "@react-google-maps/api";
import { CircularProgress } from "@mui/material";
import MenuPerfil from "./MenuPerfil";
import Navbar from "./NavBar";
import MenuEmpresa from "./MenuEmpresa";
import TiposOcorrencia from "../views/tiposOcorrencia/TiposOcorrencia";
import { renderCopyright } from "../bibliotecas/funcoes";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ehUsuarioMasterAtom, empresaAtivaAtom, empresasAtom, empresasManuaisAtom, permissoesAtom, usuarioAtom } from "../store";
import MacroRegioes from "../views/macroRegioes/MacroRegioes";
import { verificarPermissao } from "../bibliotecas/funcoes";
import { Typography } from "@mui/material";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries = ["drawing"];

const Dashboard = lazy(() => import("../views/dashboard/Dashboard"));
const Login = lazy(() => import("../views/login/Login"));
const Usuarios = lazy(() => import("../views/usuarios/Usuarios"));
const Contatos = lazy(() => import("../views/contatos/Contatos"));
const Enquetes = lazy(() => import("../views/enquetes/Enquetes"));
const Premiacoes = lazy(() => import("../views/premiacoes/Premiacoes"));
const Regioes = lazy(() => import("../views/regioes/Regioes"));
const Moradores = lazy(() => import("../views/moradores/Moradores"));
const Empresas = lazy(() => import("../views/empresas/Empresas"));
const Ajuda = lazy(() => import("../views/ajuda/Ajuda"));

const FallBackPadrao = () => {
  const error = useRouteError();

  return (
    <div style={{ display: "flex", width: "100vw", height: "100vh", alignItems: "center", justifyContent: "center", whiteSpace: "pre", flexDirection: "column" }}>
      <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
        {"v." + process.env.REACT_APP_VERSION}
      </Typography>
      <Typography variant="body2" color="rgba(0, 0, 0, 1.0)">
        <p>Ocorreu um erro:</p>
      </Typography>
      <Typography variant="body2" color="rgba(0, 0, 0, 1.0)">
        <pre>{error.data}</pre>
      </Typography>
    </div>
  );
};

const Composicao = () => {
  const [carregando, setCarregando] = useState(true);
  const [usuario, setUsuario] = useAtom(usuarioAtom);
  const permissoes = useAtomValue(permissoesAtom);
  const ehUsuarioMaster = useAtomValue(ehUsuarioMasterAtom);
  const setEmpresaAtiva = useSetAtom(empresaAtivaAtom);
  const setEmpresas = useSetAtom(empresasAtom);
  const setEmpresasManuais = useSetAtom(empresasManuaisAtom);

  const { isLoaded } = useJsApiLoader({
    id: "script-loader",
    version: "weekly",
    googleMapsApiKey: API_KEY,
    libraries,
    language: "pt",
    region: "br",
  });

  const handleLogarUsuario = () => {
    setCarregando(true);
    validarEstado();
  };

  const handleDeslogarUsuario = async () => {
    setCarregando(true);
    const res = await deslogarUsuario();
    if (res.tipo === "SUCESSO") {
      localStorage.removeItem("usuario_geo");
      validarEstado();
    } else if (res.tipo === "ERRO") {
      throw res.mensagem;
    }
  };

  const validarUsuarioLogado = async () => {
    if (localStorage.getItem("usuario_geo") !== null) {
      const res = await verificarUsuario(localStorage.getItem("usuario_geo"));

      if (res.tipo === "TOKENINVALIDO"
        || res.tipo === "USUARIOINATIVO"
        || res.tipo === "USUARIOPENDENTE"
        || res.tipo === "ERRO") {
        localStorage.removeItem("usuario_geo");
        return (null);
      } else if (res.tipo === "SUCESSO") {
        return (res.dados);
      }
    } else {
      return (null);
    }
  };

  const validarEstado = async () => {
    const dadosUsuarioLogado = await validarUsuarioLogado();
    if (dadosUsuarioLogado) {
      setUsuario(dadosUsuarioLogado);
      const resEmpresas = await buscarEmpresas(JSON.stringify({ situacoes: [true], idEmpresa: (dadosUsuarioLogado.idEmpresa === null && dadosUsuarioLogado.ehAdmin) ? null : dadosUsuarioLogado.idEmpresa }));
      if (resEmpresas.tipo === "SUCESSO") {
        setEmpresas(resEmpresas.dados);
        setEmpresaAtiva(resEmpresas.dados.length > 0 ? { id: resEmpresas.dados[0].id } : null);

        if (dadosUsuarioLogado.idEmpresa === null && dadosUsuarioLogado.ehAdmin) {
          const resEmpresasManuais = await buscarEmpresasManuais();
          if (resEmpresasManuais.tipo === "SUCESSO") {
            setEmpresasManuais(resEmpresasManuais.dados);
          }
        }

        setCarregando(false);
      }
    } else {
      setUsuario(null);
      setCarregando(false);
    }
  };

  useEffect(() => {
    validarEstado();
  }, []);

  let componente = null;

  if (!carregando && isLoaded) {
    if (usuario) {
      const Layout = () => {
        return (
          <div style={{ display: "flex", flex: 1, height: "100vh", width: "100vw", flexDirection: "column" }}>
            <div style={{ display: "flex", flex: 0.5, backgroundColor: "rgba(60, 56, 52, 1)", filter: "drop-shadow(1px 0px 1px rgba(60, 56, 52, 0.5))" }}>
              <div style={{ display: "flex", flex: "0 0 15%", justifyContent: "center", alignItems: "center" }}>
                <img
                  src="./assets/images/logo_horizontal.png"
                  width="120"
                  style={{ objectFit: "contain" }}
                  alt="logo_horizontal"
                />
              </div>
              <div style={{ display: "flex", flex: "1 1 65%" }}>
              </div>
              <div style={{ display: "flex", flex: "0 0 15%", alignItems: "center", justifyContent: "flex-end", marginRight: 2 }}>
                <MenuEmpresa />
              </div>
              <div style={{ display: "flex", flex: "0 0 5%", alignItems: "center", justifyContent: "flex-end", paddingRight: "1%" }}>
                <MenuPerfil handleDeslogar={handleDeslogarUsuario} />
              </div>
            </div>
            <div style={{ display: "flex", flex: 9 }}>
              <div style={{ display: "flex", flex: 1.5, justifyContent: "center", alignItems: "flex-start", backgroundColor: "#fff", borderRight: "0.5px solid rgba(60, 56, 52, 1)", filter: "drop-shadow(1px 0px 2px rgba(60, 56, 52, 0.5))" }}>
                <Navbar />
              </div>
              <div style={{ flex: 8.5, backgroundColor: "#fff" }}>
                <Outlet />
              </div>
            </div>
            <div style={{ display: "flex", flex: 0.5, justifyContent: "center", alignItems: "center", backgroundColor: "rgba(60, 56, 52, 1)" }}>
              {renderCopyright()}
            </div>
          </div>
        );
      };

      let rotas = [];

      rotas.push({ path: "/", id: "inicio", element: <Dashboard />, errorElement: <FallBackPadrao /> });
      if (verificarPermissao(permissoes, usuario, "USUARIOS") === true) {
        rotas.push({ path: "usuarios", id: "usuarios", element: <Usuarios modoTela="listar" />, errorElement: <FallBackPadrao /> });
      }
      if (verificarPermissao(permissoes, usuario, "CONTATOS") === true) {
        rotas.push({ path: "contatos", id: "contatos", element: <Contatos tipoDemanda={1} modoTela="listar" />, errorElement: <FallBackPadrao /> });
      }
      if (verificarPermissao(permissoes, usuario, "ENQUETES") === true) {
        rotas.push({ path: "enquetes", id: "enquetes", element: <Enquetes tipoPesquisa={1} modoTela="listar" />, errorElement: <FallBackPadrao /> });
      }

      if (usuario.usarPremiacoes) {
        if (verificarPermissao(permissoes, usuario, "PREMIAÇÕES") === true) {
          rotas.push({ path: "premiacoes", id: "premiacoes", element: <Premiacoes modoTela="listar" />, errorElement: <FallBackPadrao /> });
        }
      }

      if (ehUsuarioMaster === true) {
        rotas.push({ path: "macroRegioes", id: "macroRegioes", element: <MacroRegioes modoTela="listar" />, errorElement: <FallBackPadrao /> });
        rotas.push({ path: "regioes", id: "regioes", element: <Regioes modoTela="listar" />, errorElement: <FallBackPadrao /> });
      }

      if (verificarPermissao(permissoes, usuario, "MORADORES") === true) {
        rotas.push({ path: "moradores", id: "moradores", element: <Moradores modoTela="listar" />, errorElement: <FallBackPadrao /> });
      }
      if (verificarPermissao(permissoes, usuario, "EMPRESAS") === true) {
        rotas.push({ path: "empresas", id: "empresas", element: <Empresas modoTela="listar" />, errorElement: <FallBackPadrao /> });
      }
      if (verificarPermissao(permissoes, usuario, "TIPOS_OCORRENCIA") === true) {
        rotas.push({ path: "tiposOcorrencia", id: "tiposOcorrencia", element: <TiposOcorrencia modoTela="listar" />, errorElement: <FallBackPadrao /> });
      }
      rotas.push({ path: "ajuda", id: "ajuda", element: <Ajuda />, errorElement: <FallBackPadrao /> });

      const router = createBrowserRouter([
        {
          element: <Layout />,
          errorElement: <FallBackPadrao />,
          children: rotas,
        }
      ]);

      componente = (<RouterProvider router={router} />);
    } else if (!usuario) {
      componente = (
        <div style={{ display: "flex", flex: 1, height: "100vh", width: "100vw", flexDirection: "column" }}>
          <Login
            handleLogar={handleLogarUsuario}
          />
        </div>
      );
    }
  } else {
    componente = (
      <div style={{ display: "flex", width: "100vw", height: "100vh", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress color="warning" size={60} />
      </div>
    );
  }

  return componente;
};

export default memo(Composicao);
