import http from "../http-common";
import authHeader from "../bibliotecas/authHeader";

class ConfiguracoesDataService {
  buscar(id) {
    return http.get(`/configuracoes/${id}`, { headers: authHeader() });
  }

  alterar(data) {
    return http.put("/configuracao", data, { headers: authHeader() });
  }

  remover(id) {
    return http.delete(`/configuracao/${id}`, { headers: authHeader() });
  }
}

const configuracoesDataService = new ConfiguracoesDataService();

export default configuracoesDataService;
