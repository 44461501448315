import { atom } from "jotai";

const empresasAtom = atom([]);
const empresasManuaisAtom = atom([]);
const empresaAtivaAtom = atom(null);
const atualizarMenuEmpresasAtom = atom(null);
const usuarioAtom = atom(null);
const ehUsuarioMasterAtom = atom((get) => {
    const usuario = get(usuarioAtom);
    if (!usuario) {
        return false;
    } else {
        return usuario.idEmpresa === null && usuario.ehAdmin === true;
    }
});
const permissoesAtom = atom([]);

export {
    empresasAtom,
    empresasManuaisAtom,
    empresaAtivaAtom,
    atualizarMenuEmpresasAtom,
    usuarioAtom,
    ehUsuarioMasterAtom,
    permissoesAtom,
};